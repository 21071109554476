import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as styles from '../../scss/blog.module.scss';

const CategorySelect = ({ selected, onSelect }) => {
    const data = useStaticQuery(graphql`
    {
        allWpCategory {
            nodes {
              slug
              name
            }
        }
    }
  `);

    useEffect(() => {
        console.log("CATEGORY NEW LOADED")
    })
    useEffect(() => {
        console.log("CATEGORIES", selected)
    }, [selected])

    return (
        <div className={styles.categorySelectWrapper}>
            {
                data.allWpCategory.nodes.map(category => {
                    return <div onClick={() => onSelect(category.slug)} className={`${styles.categorySelectContainer} ${selected?.includes(category.slug) ? styles.selected : undefined}`}>
                        {category.name}
                    </div>
                })
            }
        </div>
    )



}

export default CategorySelect


